html {
  overflow-y: scroll;
}

div#container {
    margin-left: auto;
    margin-right: auto;
    width: 70vw;
    padding-left: 100px;
    padding-right: 100px;
    @media(max-width:1400px) {
      width: 90vw;
      padding-left: 10px;
      padding-right: 10px;
    }
     @media(max-width:1024px){
      width: 95vw;  
      padding-left: 5px;
      padding-right: 5px;
    }
}
.little {
  padding-left: 50px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}
div.little > img {
  width: 500px;
  &#timeout_error {
    width:400px;
  }
}

section#title {
  margin-top: 0px;
  margin-bottom: 0px;

  h1 {
    margin-bottom: 20px;
    font-size: 2.3em;
  }
  p {
    font-size: 1.1em;
  }
}
div.CodeMirror {
  box-shadow: none !important;
  height: auto;
  font-size: 1.2em;
}

section.inputspace {
  &.same-width {
    margin-top: 0px;
  }
  div.CodeMirror {
    border: 1px solid rgba(0,0,0,0.125);
  }
}

a {
  color: #ff6600;
  &:hover {
    color: #660099;
    text-decoration: none;
  }
}
code.card {
  margin-bottom: 30px;
  margin-right: 10%;
}

p.info {
  font-size: 1em;
}

div.parallel {
  display: flex;
  flex-direction: row;
  &:last-child {
    margin-bottom: 50px;
  }
}
section.same-width {
  width: 50%;
}
section.big-width {
  //padding-left: 5%;
  width: 70%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  p {
    font-size: 1.2em;
  }
}
section.small-width {
  width: 30%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: 0.25rem;
  margin-top: 10px;
  margin-bottom: 10px;
  /* div.CodeMirror {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: 0.25rem;
  } */
  div.CodeMirror-scroll {
    overflow: hidden !important;
  }
}

.btn-outline-info {
  color: #660099;
  border-color: #660099;
  &:hover {
    background-color: #660099;
    border-color: #660099;
    box-shadow: 0 0 0 0.2rem rgba(102, 0, 153, 0.25);
  }
  &:visited {
    box-shadow: None;
  }
  &:active, &:active:focus {
    border-color: #873eac;
    box-shadow: 0 0 0 0.2rem rgba(102, 0, 153, 0.25);
  }
  &.active-tab {
    border-color: #660099;
    background-color: #660099;
    color: white;
    box-shadow: none;
  }
}

.btn-info.btn-info.btn-info {
  background-color: #660099;
  border-color: #660099;
  margin-bottom: 30px;
  margin-top: 30px;
  &:visited {
    box-shadow: None;
  }
  &:focus {
    background-color: #660099;
    border-color: #660099;
    box-shadow: None;
  }
  &:hover {
    background-color: #ff6600;
    border-color: #ff6600;
    box-shadow: 0 0 0 0.2rem rgba(255, 102, 0, 0.25);
  }
  &:active, &:active:focus {
    background-color: #873eac;
    border-color: #873eac;
    box-shadow: 0 0 0 0.2rem rgba(102, 0, 153, 0.25);
  }
}
button {
  .btn.btn-outline-info.btn-sm {
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    font-size: 16px;
    color: #660099;
  }
}
.btn-outline-info.btn-outline-info.btn-outline-info {
  &.active-tab {
    background-color:#660099;
    color: white;
    border-color: #660099;
    box-shadow: none;
  }
  &:focus {
    border-color: #660099;
    box-shadow: None;
  }
  &:hover {
    border-color: #660099;
    box-shadow: 0 0 0 0.2rem rgba(102, 0, 153, 0.25);
    background-color: #660099;
  }
  &:active, &:active:focus {
    background-color: #873eac;
    border-color: #873eac;
    box-shadow: 0 0 0 0.2rem rgba(102, 0, 153, 0.25);
  }
}
section#nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 0px;
  ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding-left: 0px;
    li {
      color: #660099;
      text-decoration: underline;
      padding-left: 10px;
      padding-right: 10px;
      &:first-child {
        padding-left: 0px;
      }
    }
    button {
      font-size: 16px;
    }
  }
}

.turquoise-border textarea {
    border: 1px solid #660099;
    outline-color: #660099;
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(102, 0, 153, .25);
    }
    &:active {
      box-shadow: 0 0 0 0.2rem rgba(102, 0, 153,0.25);
    }
}

code {
    color: #660099;
}

.error {
    color:#ff6600;
}

button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 10px;
}

textarea {
    height: 150px;
    border-radius: 0.25rem;
    white-space:pre-wrap;
    padding: 2px 5px;
}

textarea#params {
    height: 60px;
    font-family: monospace;
}

section#outputspace.card {
  margin-left: 20px;
  margin-top: 0px;
} 


section {
    margin-top: 20px;
    margin-bottom: 20px;
    h2 {
        font-size: 16px;
    }
}

.card {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    h1 {
      font-size: 1.5em;
    }
}

.CodeMirror-wrap pre {
  word-break: break-word;
}

/* https://stackoverflow.com/questions/19756934/codemirror-auto-line-break */
#outputspace .CodeMirror-cursor, 
.examples .CodeMirror-cursor {
  visibility: hidden;
  display: none;
}

.examples code {
  display: none;
}
.doc code {
  display: none;
}
div.doc {
  p {
    font-size: 1.1rem;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.8em;
  }
  h3 {
    font-size: 1.5em;
  }
}

